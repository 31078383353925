@font-face {
  font-family: 'CustomFont';
  src: url('./fonts/Favela-Medium.ttf') format('truetype');
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body {
  background: black;
  font-family: 'CustomFont', sans-serif;
}

.logo {
  display: flex;
  width: 17%;
  margin: auto;
  top: 20%;
  animation: fadeIn 2s ease-in;
  transform: translate(-50%, -50%);
  position: fixed;
}

@media (max-width: 600px) {
  .logo {
    width: 40%
  }
}

.announcement {
  color: #fcd117;
}

.about-section {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 480px;
  height: auto;
  /* adjust as needed */
  background: none;
  padding: 20px;
  box-sizing: border-box;
  animation: fadeIn 2s ease-in;
  font-size: 13px;
  transform: translate(-50%, -50%);
  /* centers the div */
}

@media (max-width: 600px) {
  .about-section {
    height: 80%;
    width: 80%;
    box-sizing: content-box;
    padding-top: 10%;
    overflow-y: scroll;
    overflow-x: scroll;
  }
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #009e4a;
  position: relative;
  /* Add this line */
}

form.url-form {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  background-color: black;
  color: #009e4a;
  width: 100%;
  font-family: 'CustomFont', sans-serif;
  max-width: 600px;
  position: absolute;
  /* Add this line */
  top: 40%;
  /* Add this line */
  transform: translateY(-30%);
  /* Add this line */
}

@media (max-width: 600px) {
  form.url-form {
    font-size: 89%;
  }
}

form.url-form .label {
  margin-right: 10px;
}

input {
  flex-grow: 1;
  border: none;
  padding: 0.5em;
  background: black;
  color: #009e4a;
  margin-right: 15px;
  /* Adjusted the margin here */
}

.recommendation-container {
  transform: translateX(7%);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px) {
  .recommendation-container {
    font-size: 85%;
  }

  .dive-button {
    font-size: 85%;
  }
}

.dive-button,
.about-close-button,
button.submit-button {
  background: none;
  border: none;
  color: #009e4a;
  text-decoration: none;
  cursor: pointer;
  font-family: 'CustomFont';
}


.about-close-button {
  margin: autio;
  font-size: 15px;
  color: white
}

.dive-button {
  margin-right: 55px !important;
  transform: translateX(3%);
}

.dive-button:hover {
  color: white;
}

.about-close-button:hover {
  color: #009e4a;
}

button.submit-button:hover {
  color: white;
}

.error-message {
  color: red;
  position: absolute;
  /* Add this line */
  font-family: 'CustomFont';
  top: 30%
}

.recommendations {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  position: absolute;
  /* Add this line */
  top: 45%;
  /* Add this line */
}

.recommendations.visible {
  visibility: visible;
  opacity: 1;
}


.recommendations a {
  color: #009e4a;
  text-decoration: none;
}

.recommendations a:hover {
  color: white;
}

.recommendations a.show {
  opacity: 0;
}